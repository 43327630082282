export const colors = {
    gray: {
        100: "#F7F7FC",
        200: "#EFF0F6",
        300: "#D9DBE9",
        400: "#A0A3BD",
        500: "#6E7191",
        600: "#4E4B66",
        700: "#262338",
        800: "#14142A"
    },
    purple: {
        100: "#EBECFE",
        200: "#BFBEFC",
        300: "#A996FF",
        400: "#9875FF",
        500: "#8B4DFF",
        600: "#610BEF",
        700: "#4700AB",
        800: "#2E036A"
    },
    blue: {
        100: "#E3FEFF",
        200: "#8DE9FF",
        300: "#50C8FC",
        400: "#2AA8F8",
        500: "#0576F0",
        600: "#005BD4",
        700: "#0041AC",
        800: "#002463"
    },
    green: {
        100: "#EAF9DE",
        200: "#CBFFAE",
        300: "#A6F787",
        400: "#7DF75A",
        500: "#18BB0C",
        600: "#008A00",
        700: "#067306",
        800: "#07540A"
    },
    yellow: {
        100: "#FFF8E9",
        200: "#FFE6B0",
        300: "#FFDF9A",
        400: "#FDCC62",
        500: "#EAAC30",
        600: "#946300",
        700: "#6E5523",
        800: "#534019"
    },
    pink: {
        100: "#FFECFC",
        200: "#FFABE8",
        300: "#FF75CB",
        400: "#FF54AF",
        500: "#E3026F",
        600: "#CA024F",
        700: "#9E0038",
        800: "#5B001E"
    },
    orange: {
        100: "#FFF3EC",
        200: "#FFCAAB",
        300: "#FFA875",
        400: "#FF9354",
        500: "#E35502",
        600: "#CA4B02",
        700: "#9E3A00",
        800: "#5B2200"
    }
}